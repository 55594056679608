import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getAddressById, getAddressTransactions, getAddressMinedBlocks } from '../services/api';
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/HourglassEmpty';

function AddressDetailsPage() {
  const { address } = useParams();
  const [addressData, setAddressData] = useState(null);
  const [transactions, setTransactions] = useState([]); // ������ ����������
  const [transactionPage, setTransactionPage] = useState(1); // ������� �������� ��� ����������
  const [totalTransactionPages, setTotalTransactionPages] = useState(1); // ����� ������� � ������������

  const [blocks, setBlocks] = useState([]); // ������ ������
  const [blockPage, setBlockPage] = useState(1); // ������� �������� ��� ������
  const [totalBlockPages, setTotalBlockPages] = useState(1); // ����� ������� � �������

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 20; // ���������� ��������� �� ��������

  useEffect(() => {
    const fetchAddressData = async () => {
      setLoading(true);
      try {
        const response = await getAddressById(address);
        setAddressData(response.data);
        setError(null);
      } catch (err) {
        console.error("Failed to load address data:", err);
        setError("Failed to load address details.");
      } finally {
        setLoading(false);
      }
    };

    fetchAddressData();
  }, [address]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const txResponse = await getAddressTransactions(address, transactionPage, itemsPerPage);
        setTransactions(txResponse.data.transactions);
        setTotalTransactionPages(txResponse.data.totalPages);
      } catch (err) {
        console.error("Failed to load transactions:", err);
      }
    };

    fetchTransactions();
  }, [address, transactionPage]);

  useEffect(() => {
    const fetchBlocks = async () => {
      try {
        const blockResponse = await getAddressMinedBlocks(address, blockPage, itemsPerPage);
        setBlocks(blockResponse.data.blocks);
        setTotalBlockPages(blockResponse.data.totalPages);
      } catch (err) {
        console.error("Failed to load blocks:", err);
      }
    };

    fetchBlocks();
  }, [address, blockPage]);

  // ��������� ��� ����������
  const handleNextTransactionPage = () => {
    if (transactionPage < totalTransactionPages) {
      setTransactionPage(transactionPage + 1);
    }
  };

  const handlePreviousTransactionPage = () => {
    if (transactionPage > 1) {
      setTransactionPage(transactionPage - 1);
    }
  };

  // ��������� ��� ������
  const handleNextBlockPage = () => {
    if (blockPage < totalBlockPages) {
      setBlockPage(blockPage + 1);
    }
  };

  const handlePreviousBlockPage = () => {
    if (blockPage > 1) {
      setBlockPage(blockPage - 1);
    }
  };

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return <Typography color="error" align="center">{error}</Typography>;
  }

  if (!addressData) return null;

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Address Details</Typography>
      <Box sx={{ mb: 3 }}>
        <Typography variant="body1">
          <strong>Address:</strong> {addressData.address}
        </Typography>
        <Typography variant="body1">
          <strong>Balance:</strong> {addressData.balance}
        </Typography>
        <Typography variant="body1" style={{ display: 'flex', alignItems: 'center' }}>
          <strong>Activity Status:</strong>
          {addressData.txCount > 0 ? (
            <>
              <CheckCircleIcon sx={{ color: 'green', ml: 1 }} />
              <span style={{ marginLeft: '5px' }}>Active</span>
            </>
          ) : (
            <>
              <PendingIcon sx={{ color: 'orange', ml: 1 }} />
              <span style={{ marginLeft: '5px' }}>Inactive</span>
            </>
          )}
        </Typography>
        <Typography variant="body1">
          <strong>Transactions:</strong> {addressData.txCount}
        </Typography>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h5" gutterBottom>Transactions</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Transaction Hash</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Block</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.length > 0 ? (
              transactions.map((tx) => (
                <TableRow key={tx.hash}>
                  <TableCell>
                    <Link to={`/transactions/${tx.hash}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                      {tx.hash}
                    </Link>
                  </TableCell>
                  <TableCell>{tx.value}</TableCell>
                  <TableCell>
                    <Link to={`/block/${tx.blockNumber}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                      {tx.blockNumber}
                    </Link>
                  </TableCell>
                  <TableCell>{tx.status}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No transactions found for this address.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" mt={2}>
        <Button onClick={handlePreviousTransactionPage} disabled={transactionPage === 1}>Previous</Button>
        <Typography variant="body1" sx={{ mx: 2 }}>Page {transactionPage} of {totalTransactionPages}</Typography>
        <Button onClick={handleNextTransactionPage} disabled={transactionPage === totalTransactionPages}>Next</Button>
      </Box>

      <Divider sx={{ my: 4 }} />

      <Typography variant="h5" gutterBottom>Mined Blocks</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Block Number</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Transaction Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blocks.length > 0 ? (
              blocks.map((block) => (
                <TableRow key={block.number}>
                  <TableCell>
                    <Link to={`/block/${block.number}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                      {block.number}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {block.timestamp ? new Date(block.timestamp).toLocaleString() : "No Date Available"}
                  </TableCell>
                  <TableCell>{block.transactionCount}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No blocks found for this address.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" mt={2}>
        <Button onClick={handlePreviousBlockPage} disabled={blockPage === 1}>Previous</Button>
        <Typography variant="body1" sx={{ mx: 2 }}>Page {blockPage} of {totalBlockPages}</Typography>
        <Button onClick={handleNextBlockPage} disabled={blockPage === totalBlockPages}>Next</Button>
      </Box>
    </Container>
  );
}

export default AddressDetailsPage;
