import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText, Button } from '@mui/material';
import { Link } from 'react-router-dom'; // ������ Link ��� �������������
import { getLatestTransactions } from '../services/api';

const TRANSACTIONS_PER_PAGE = 10;

function TransactionPage() {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const response = await getLatestTransactions(); // �������� ��� ����������
        setTransactions(response.data);
        setError(null);
      } catch (err) {
        console.error("Failed to load transactions:", err);
        setError("Failed to load transactions.");
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  const totalPages = Math.ceil(transactions.length / TRANSACTIONS_PER_PAGE);
  const currentTransactions = transactions.slice(
    (currentPage - 1) * TRANSACTIONS_PER_PAGE,
    currentPage * TRANSACTIONS_PER_PAGE
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return <Typography color="error" align="center">{error}</Typography>;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Recent Transactions</Typography>
      {currentTransactions.length > 0 ? (
        <>
          <List>
            {currentTransactions.map((tx) => (
              <ListItem key={tx.hash} divider>
                <ListItemText
                  primary={
                    <Link to={`/transactions/${tx.hash}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                      Transaction Hash: {tx.hash}
                    </Link>
                  }
                  secondary={
                    <>
                      <Typography variant="body2">
                        <strong>Block Number:</strong> {tx.blockNumber}
                      </Typography>
                      <Typography variant="body2">
                        <strong>From:</strong>{' '}
                        <Link to={`/addresses/${tx.from}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                          {tx.from}
                        </Link>
                      </Typography>
                      <Typography variant="body2">
                        <strong>To:</strong>{' '}
                        {tx.to ? (
                          <Link to={`/addresses/${tx.to}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                            {tx.to}
                          </Link>
                        ) : (
                          'Contract Creation'
                        )}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Value:</strong> {tx.value} LBRT
                      </Typography>
                      <Typography variant="body2">
                        <strong>Gas:</strong> {tx.gas}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Gas Price:</strong> {tx.gasPrice}
                      </Typography>
                      <Typography variant="body2" color={tx.status === 'Confirmed' ? 'green' : 'orange'}>
                        <strong>Status:</strong> {tx.status}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button disabled={currentPage === 1} onClick={handlePreviousPage}>Previous</Button>
            <Typography>Page {currentPage} of {totalPages}</Typography>
            <Button disabled={currentPage === totalPages} onClick={handleNextPage}>Next</Button>
          </Box>
        </>
      ) : (
        <Typography>No transactions found.</Typography>
      )}
    </Container>
  );
}

export default TransactionPage;
